body {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

#root {
  min-width: 300px;
  position: absolute;
  z-index: -1;
  left: auto;
  width: 100%;
}

a {
  text-decoration: none;
}

.color-selector {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
}

.mdc-layout-grid {
  padding: 0;
}

.mdc-card {
  flex-direction: column;
  justify-content: space-between;
}

.card-grid {
  max-width: 1200px;
  padding: 6rem 0;
}

.intro-row {
  max-width: 1200px;
  margin: auto;
}

.projects-wrapper {
  width: 100%;
}

.projects {
  max-width: 1200px;
  margin: auto;
  padding: 56px;
  font-size: 2.5rem;
  font-family: 'Roboto Mono', monospace;
  text-align: center;
}

.mdc-layout-grid__inner {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.intro {
  font-size: 2.5rem;
  font-family: 'Exo 2', sans-serif;
  display: flex;
  align-items: center;
  height: 72vh;
  padding: 1rem;
  text-align: center;
  margin-top: 10px;
}

.social-icons {
  font-size: 2rem;
  font-family: 'Exo 2', sans-serif;
  display: flex;
  flex-direction: row;
  height: 25vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.social-icons-inner > a {
  margin: 10px;
}

.footer-wrapper {
  width: 100%;
}

.footer {
  font-size: 1.5rem;
  font-family: 'Roboto Mono', monospace;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  margin: auto;
}

.footer div {
  text-align: center;
}

.social-icons-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-icons-footer a {
  margin: 10px;
}

.mdc-typography--subtitle2 {
  font-size: 165%;
  font-family: 'Roboto Mono', monospace;
}

.mdc-typography--body1 {
  font-family: 'Roboto Mono', monospace;
}

.about {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin: 2rem 0;
  font-family: 'Roboto Mono', monospace;
  display: flex;
  flex-direction: column;
}

.about-section2 {
  padding-top: 2rem;
}

.mdc-button {
  font-size: 19px;
}

@media only screen and (min-width: 415px) {
  .intro-row {
    height: 100vh;
    justify-content: left;
    position: static;
  }

  .intro {
    font-size: 2.25rem;
    font-family: 'Exo 2', sans-serif;
    display: flex;
    align-items: center;
    height: 100vh;
    width: 65%;
    text-align: center;
    margin-top: 0;
    justify-content: right;
    position: static;
  }

  .social-icons {
    height: 100vh;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
  }

  .social-icons-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 60%;
    max-height: 450px;
  }

  .social-icons-inner > a {
    margin: 0;
  }
}

@media (min-width: 764px) {
  .intro {
    font-size: 2.5rem;
    text-align: left;
    justify-content: left;
  }
}

@media only screen and (min-width: 1025px) {
  .intro {
    font-size: 3rem;
    font-family: 'Exo 2', sans-serif;
    display: flex;
    height: 100vh;
    text-align: left;
    justify-content: left;
  }

  .social-icons {
    position: relative;
  }

  .about {
    flex-direction: row;
    max-width: 1200px;
    margin: 4rem auto;
  }

  .about-section1 {
    width: 60%;
    padding: 0;
    padding-right: 1rem;
  }

  .about-section2 {
    width: 45%;
    padding: 0;
    padding-right: 1rem;
  }
}
